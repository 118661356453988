import { Athlete } from './athlete.model';
import BaseService from '../base-service';
import axios from '@/plugins/axios.plugin';

export class AthleteService extends BaseService<Athlete> {
  get endpoint() {
    return 'athlete';
  }

  generateSampleAthlete() {
    return axios.post(`${this.endpoint}/sample`);
  }
  createUserForAthlete(id: string) {
    return axios.post(`${this.endpoint}/${id}/user`);
  }
}
