import { BaseModel } from '@/shared/types/base.model';
import { Company } from '../company/company.model';

export class Athlete extends BaseModel {
  public readonly name: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public dateOfBirth: Date;
  public gender: string;
  public createUserForAthlete: boolean;
  public isUser?: boolean;
  public company?: string | Company;

  constructor() {
    super();
  }
}
