import { Company } from '@/api/company/company.model';
import { CompanyService } from '@/api/company/company.service';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const companyService = new CompanyService();

interface CompanyStoreState {
  all: Company[];
  current: Company;
}

const state: CompanyStoreState = {
  all: [],
  current: new Company(),
};

const getters = {
  all: (state: CompanyStoreState): Company[] => {
    return state.all;
  },
  current: (state: CompanyStoreState): Company => {
    return state.current;
  },
};

const mutations = {
  setAll(state: CompanyStoreState, companies: Company[]) {
    Vue.set(state, 'all', companies);
  },
  setCurrent(state: CompanyStoreState, company: Company) {
    Vue.set(state, 'current', company);
  },
};

const actions = {
  async create(
    context: ActionContext<CompanyStoreState, unknown>,
    company: Company,
  ): Promise<Company> {
    const newCompany = await companyService.create(company);
    return newCompany;
  },
  async fetchById(
    context: ActionContext<CompanyStoreState, unknown>,
    id: string,
  ): Promise<Company> {
    const company = await companyService.fetchOne(id);
    context.commit('setCurrent', company);
    return company;
  },
  async updateById(
    context: ActionContext<CompanyStoreState, unknown>,
    company: Company,
  ): Promise<Company> {
    const resultCompany = await companyService.update(company._id, company);
    context.commit('setCurrent', resultCompany);
    return resultCompany;
  },
  async fetchAll(
    context: ActionContext<CompanyStoreState, unknown>,
    payload?: Filter,
  ): Promise<Company[]> {
    const companies = await companyService.fetchAll(payload);
    context.commit('setAll', companies);
    return companies;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
