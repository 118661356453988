import { Activate } from '@/api/auth/activate.model';
import { Signup } from '@/api/auth/signup.model';
import { Activation } from '@/api/auth/activation.model';
import AuthService from '@/api/auth/auth.service';
import { LoginResult } from '@/api/auth/login-result.model';
import { Login } from '@/api/auth/login.model';
import { Registration } from '@/api/auth/registration.model';
import { RequestPasswordReset } from '@/api/auth/request-password-reset.model';
import { ResetPassword } from '@/api/auth/reset-password.model';
import { User } from '@/api/user/user.model';
import axios from '@/plugins/axios.plugin';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';

Vue.use(Vuex);

interface AuthStoreState {
  currentLogin: LoginResult;
  activationData: Activation;
  token: string;
  refreshToken: string;
}

const state = {
  currentLogin: {},
  activationData: new Activation(),
  token: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refresh-token'),
};

const getters = {
  currentLogin: (state: AuthStoreState) => {
    return state.currentLogin;
  },
  activationData: (state: AuthStoreState) => {
    return state.activationData;
  },
  token: (state: AuthStoreState) => {
    return state.token;
  },
  refreshToken: (state: AuthStoreState) => {
    return state.refreshToken;
  },
  logout: (state: AuthStoreState) => {
    delete state.currentLogin;
    delete state.token;
  },
};

const mutations = {
  setCurrentLogin(state: AuthStoreState, user: User) {
    Vue.set(state, 'currentLogin', user);
  },
  setToken(state: AuthStoreState, token: string) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token',
    )}`;
    Vue.set(state, 'token', token);
  },
  setRefreshToken(state: AuthStoreState, refreshToken: string) {
    localStorage.setItem('refresh-token', refreshToken);
    Vue.set(state, 'refreshToken', refreshToken);
  },
  setActivationData(state: AuthStoreState, activation: Activation) {
    Vue.set(state, 'activationData', activation);
  },
  logout(state: AuthStoreState) {
    delete state.currentLogin;
    delete state.token;
    localStorage.removeItem('token');
    localStorage.removeItem('refresh-token');
    delete axios.defaults.headers.common.Authorization;
  },
};

const actions = {
  async getActivationData(
    context: ActionContext<AuthStoreState, unknown>,
    token: string,
  ) {
    try {
      const result = await AuthService.getActivationData(token);
      context.commit('setActivationData', result);
      return result;
    } catch (e) {
      context.commit(
        'app/addToast',
        new Toast('Activation token not found', ToastType.ERROR),
        {
          root: true,
        },
      );
      return false;
    }
  },
  async login(context: ActionContext<AuthStoreState, unknown>, login: Login) {
    try {
      const result = await AuthService.login(login);
      context.commit('setCurrentLogin', result.user);
      context.commit('setToken', result.bearerToken);
      context.commit('setRefreshToken', result.refreshToken);
      return result;
    } catch (e) {
      context.commit(
        'app/addToast',
        new Toast('Login failed', ToastType.ERROR),
        {
          root: true,
        },
      );
      return false;
    }
  },
  async loginAs(
    context: ActionContext<AuthStoreState, unknown>,
    email: string,
  ) {
    try {
      const result = await AuthService.loginAs(email);
      context.commit('setCurrentLogin', result.user);
      context.commit('setToken', result.bearerToken);
      context.commit('setRefreshToken', result.refreshToken);
      return result;
    } catch (e) {
      context.commit(
        'app/addToast',
        new Toast('Login failed', ToastType.ERROR),
        {
          root: true,
        },
      );
      return false;
    }
  },
  async activate(
    context: ActionContext<AuthStoreState, unknown>,
    activate: Activate,
  ) {
    const result = await AuthService.activate(activate);
    context.commit('setCurrentLogin', result.user);
    context.commit('setToken', result.bearerToken);
    context.commit('setRefreshToken', result.refreshToken);
    return true;
  },
  async signup(
    context: ActionContext<AuthStoreState, unknown>,
    signup: Signup,
  ) {
    const result = await AuthService.signup(signup);
    context.commit('setCurrentLogin', result.user);
    context.commit('setToken', result.bearerToken);
    context.commit('setRefreshToken', result.refreshToken);
    return true;
  },
  async requestResetPassword(
    context: ActionContext<AuthStoreState, unknown>,
    requestPasswordReset: RequestPasswordReset,
  ) {
    return AuthService.requestResetPassword(requestPasswordReset);
  },
  async resetPassword(
    context: ActionContext<AuthStoreState, unknown>,
    payload: ResetPassword,
  ) {
    return AuthService.resetPassword(payload);
  },
  async register(
    context: ActionContext<AuthStoreState, unknown>,
    payload: Registration,
  ) {
    return AuthService.register(payload);
  },
  async findByInviteToken(
    context: ActionContext<AuthStoreState, unknown>,
    inviteToken: string,
  ) {
    return AuthService.findByInviteToken(inviteToken);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
