import { BaseModel } from '@/shared/types/base.model';
import { HealthPointType } from '../health-point/type/health-point-type.enum';

export class Suggestion extends BaseModel {
  constructor(
    public title?: string,
    public description?: string,
    public dataType?: HealthPointType,
    public lowerMargin?: number,
    public upperMargin?: number,
    public order?: number,
    public variant?: string,
    public longDescription?: string,
    public company?: string,
    public active: boolean = true,
    public showToMembers: boolean = false,
    public useTimeframe: boolean = false,
    public fromDays?: number,
    public untillDays?: number,
  ) {
    super();
  }
}
