import { BaseModel } from '@/shared/types/base.model';
import { Company } from '../company/company.model';

export class ActiveCampaign extends BaseModel {
  public isActive?: boolean;
  public company?: string | Company;
  public apiKey?: string;
  public url?: string;

  constructor() {
    super();
  }
}
