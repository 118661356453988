import axios from '@/plugins/axios.plugin';
import { LoginResult } from './login-result.model';
import { Login } from './login.model';
import { ResetPassword } from './reset-password.model';
import { Registration } from './registration.model';
import { User } from '../user/user.model';
import { Activate } from './activate.model';
import { RequestPasswordReset } from './request-password-reset.model';
import { Activation } from './activation.model';
import { Signup } from './signup.model';

class AuthService {
  endpoint = 'auth';
  async login(loginData: Login): Promise<LoginResult> {
    const response = await axios.post<LoginResult>(
      `${this.endpoint}/login`,
      loginData,
    );
    return response.data;
  }

  async loginAs(email: string): Promise<LoginResult> {
    const response = await axios.post<LoginResult>(
      `${this.endpoint}/login-as`,
      { email },
    );
    return response.data;
  }

  async requestResetPassword(
    requestPasswordReset: RequestPasswordReset,
  ): Promise<void> {
    const response = await axios.post<void>(
      `${this.endpoint}/request-password-reset`,
      requestPasswordReset,
    );
    return response.data;
  }

  async resetPassword(payload: ResetPassword): Promise<void> {
    const { token, ...params } = payload;
    const response = await axios.post<void>(
      `${this.endpoint}/reset-password/${token}`,
      params,
    );
    return response.data;
  }

  async register(payload: Registration): Promise<boolean> {
    const response = await axios.post<boolean>(
      `${this.endpoint}/register`,
      payload,
    );
    return response.data;
  }

  async findByInviteToken(inviteToken: string): Promise<User> {
    const response = await axios.get<User>(
      `${this.endpoint}/invited/${inviteToken}`,
    );
    return response.data;
  }

  async getActivationData(token): Promise<Activation> {
    const response = await axios.get<Activation>(
      `${this.endpoint}/activate/${token}`,
    );
    return response.data;
  }

  async activate(activate: Activate): Promise<LoginResult> {
    const response = await axios.post<LoginResult>(
      `${this.endpoint}/activate`,
      activate,
    );
    return response.data;
  }

  async signup(signup: Signup): Promise<LoginResult> {
    const response = await axios.post<LoginResult>(
      `${this.endpoint}/signup`,
      signup,
    );
    return response.data;
  }
}

export default new AuthService();
