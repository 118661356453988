import { HealthGoal } from '@/api/health-goal/health-goal.model';
import { Filter } from '@/shared/types/filter.class';

import { HealthGoalService } from '@/api/health-goal/health-goal.service';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const healthgoalService = new HealthGoalService();

interface HealthGoalsPerType {
  [key: string]: HealthGoal[];
}

interface HealthGoalStoreState {
  all: HealthGoal[];
  dataPerType: HealthGoalsPerType;
}

const state: HealthGoalStoreState = {
  all: [],
  dataPerType: {},
};

const getters = {
  all: (state: HealthGoalStoreState): HealthGoal[] => {
    return state.all;
  },
  dataForType:
    (state: HealthGoalStoreState) =>
    (type: string): HealthGoal[] => {
      return state.dataPerType[type];
    },
};

const mutations = {
  setAll(state: HealthGoalStoreState, healthgoals: HealthGoal[]) {
    Vue.set(state, 'all', healthgoals);
  },
  setForType(
    state: HealthGoalStoreState,
    payload: { type: string; healthGoals: HealthGoal[] },
  ) {
    Vue.set(state.dataPerType, payload.type, payload.healthGoals);
  },
};

const actions = {
  async create(
    context: ActionContext<HealthGoalStoreState, unknown>,
    healthgoal: HealthGoal,
  ): Promise<HealthGoal> {
    const newHealthGoal = await healthgoalService.create(healthgoal);
    return newHealthGoal;
  },
  async fetchAll(
    context: ActionContext<HealthGoalStoreState, unknown>,
    payload?: Filter,
  ): Promise<HealthGoal[]> {
    const healthgoals = await healthgoalService.fetchAll(payload);
    context.commit('setAll', healthgoals);
    return healthgoals;
  },
  async fetchForType(
    context: ActionContext<HealthGoalStoreState, unknown>,
    payload: { type: string; filter?: Filter },
  ): Promise<HealthGoal[]> {
    const healthGoals = await healthgoalService.fetchAll(payload.filter);
    context.commit('setForType', { type: payload.type, healthGoals });
    return healthGoals;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
