import { Suggestion } from '@/api/suggestion/suggestion.model';
import { SuggestionService } from '@/api/suggestion/suggestion.service';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const suggestionService = new SuggestionService();

interface SuggestionStoreState {
  all: Suggestion[];
  current: Suggestion;
}

const state: SuggestionStoreState = {
  all: [],
  current: new Suggestion(),
};

const getters = {
  all: (state: SuggestionStoreState): Suggestion[] => {
    return state.all;
  },
  current: (state: SuggestionStoreState): Suggestion => {
    return state.current;
  },
};

const mutations = {
  setAll(state: SuggestionStoreState, suggestions: Suggestion[]) {
    Vue.set(state, 'all', suggestions);
  },
  setCurrent(state: SuggestionStoreState, suggestion: Suggestion) {
    Vue.set(state, 'current', suggestion);
  },
};

const actions = {
  async create(
    context: ActionContext<SuggestionStoreState, unknown>,
    suggestion: Suggestion,
  ): Promise<Suggestion> {
    const newSuggestion = await suggestionService.create(suggestion);
    return newSuggestion;
  },
  async fetchById(
    context: ActionContext<SuggestionStoreState, unknown>,
    id: string,
  ): Promise<Suggestion> {
    const suggestion = await suggestionService.fetchOne(id);
    context.commit('setCurrent', suggestion);
    return suggestion;
  },
  async updateById(
    context: ActionContext<SuggestionStoreState, unknown>,
    suggestion: Suggestion,
  ): Promise<Suggestion> {
    const resultSuggestion = await suggestionService.update(
      suggestion._id,
      suggestion,
    );
    context.commit('setCurrent', resultSuggestion);
    return resultSuggestion;
  },
  async fetchAll(
    context: ActionContext<SuggestionStoreState, unknown>,
    payload?: Filter,
  ): Promise<Suggestion[]> {
    const suggestions = await suggestionService.fetchAll(payload);
    context.commit('setAll', suggestions);
    return suggestions;
  },
  async deleteById(
    context: ActionContext<SuggestionStoreState, unknown>,
    id: string,
  ): Promise<Suggestion> {
    const suggestion = await suggestionService.delete(id);
    return suggestion;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
