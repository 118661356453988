import { BaseModel } from '@/shared/types/base.model';

export class AthleteNote extends BaseModel {
  public athlete: string;
  public note: string;
  public subjectDate: Date | string = new Date();
  public subjectType?: string;

  constructor() {
    super();
  }
}
