import { LeaderboardResult } from '@/api/leaderboard/leaderboard-result.model';
import { LeaderboardService } from '@/api/leaderboard/leaderboard.service';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const leaderboardService = new LeaderboardService();

interface LeaderboardStoreState {
  all: LeaderboardResult[];
}

const state: LeaderboardStoreState = {
  all: [],
};

const getters = {
  all: (state: LeaderboardStoreState): LeaderboardResult[] => {
    return state.all;
  },
};

const mutations = {
  setAll(state: LeaderboardStoreState, leaderboard: LeaderboardResult[]) {
    Vue.set(state, 'all', leaderboard);
  },
};

const actions = {
  async fetchAll(
    context: ActionContext<LeaderboardStoreState, unknown>,
    payload?: Filter,
  ): Promise<LeaderboardResult[]> {
    const leaderboard = await leaderboardService.fetchAll(payload);
    context.commit('setAll', leaderboard);
    return leaderboard;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
