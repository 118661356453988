import { MyDocument } from '@/api/my-document/my-document.model';
import { MyDocumentService } from '@/api/my-document/my-document.service';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const myDocumentService = new MyDocumentService();

interface MyDocumentStoreState {
  all: MyDocument[];
  current: MyDocument;
}

const state: MyDocumentStoreState = {
  all: [],
  current: new MyDocument(),
};

const getters = {
  all: (state: MyDocumentStoreState): MyDocument[] => {
    return state.all;
  },
  current: (state: MyDocumentStoreState): MyDocument => {
    return state.current;
  },
};

const mutations = {
  setAll(state: MyDocumentStoreState, myDocuments: MyDocument[]) {
    Vue.set(state, 'all', myDocuments);
  },
  setCurrent(state: MyDocumentStoreState, myDocument: MyDocument) {
    Vue.set(state, 'current', myDocument);
  },
};

const actions = {
  async create(
    context: ActionContext<MyDocumentStoreState, unknown>,
    myDocument: MyDocument,
  ): Promise<MyDocument> {
    const newMyDocument = await myDocumentService.create(myDocument);
    return newMyDocument;
  },
  async fetchById(
    context: ActionContext<MyDocumentStoreState, unknown>,
    id: string,
  ): Promise<MyDocument> {
    const myDocument = await myDocumentService.fetchOne(id);
    context.commit('setCurrent', myDocument);
    return myDocument;
  },
  async updateById(
    context: ActionContext<MyDocumentStoreState, unknown>,
    myDocument: MyDocument,
  ): Promise<MyDocument> {
    const resultMyDocument = await myDocumentService.update(
      myDocument._id,
      myDocument,
    );
    context.commit('setCurrent', resultMyDocument);
    return resultMyDocument;
  },
  async fetchAll(
    context: ActionContext<MyDocumentStoreState, unknown>,
    payload?: Filter,
  ): Promise<MyDocument[]> {
    const myDocuments = await myDocumentService.fetchAll(payload);
    context.commit('setAll', myDocuments);
    return myDocuments;
  },
  async deleteById(
    context: ActionContext<MyDocumentStoreState, unknown>,
    id: string,
  ): Promise<MyDocument> {
    const myDocument = await myDocumentService.delete(id);
    return myDocument;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
