import { ExternalAthleteLink } from '@/api/external-athlete-link/external-athlete-link.model';
import {
  CastType,
  Filter,
  FilterOperation,
  Operator,
} from '@/shared/types/filter.class';

import { ExternalAthleteLinkService } from '@/api/external-athlete-link/external-athlete-link.service';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const externalAthleteLinkService = new ExternalAthleteLinkService();

interface ExternalAthleteLinkStoreState {
  all: ExternalAthleteLink[];
  linksForAthlete: ExternalAthleteLink[];
  current: ExternalAthleteLink;
}

const state: ExternalAthleteLinkStoreState = {
  all: [],
  linksForAthlete: [],
  current: new ExternalAthleteLink(),
};

const getters = {
  all: (state: ExternalAthleteLinkStoreState): ExternalAthleteLink[] => {
    return state.all;
  },
  linksForAthlete: (
    state: ExternalAthleteLinkStoreState,
  ): ExternalAthleteLink[] => {
    return state.linksForAthlete;
  },
  current: (state: ExternalAthleteLinkStoreState): ExternalAthleteLink => {
    return state.current;
  },
};

const mutations = {
  setAll(
    state: ExternalAthleteLinkStoreState,
    externalAthleteLinks: ExternalAthleteLink[],
  ) {
    Vue.set(state, 'all', externalAthleteLinks);
  },
  setCurrent(
    state: ExternalAthleteLinkStoreState,
    externalAthleteLink: ExternalAthleteLink,
  ) {
    Vue.set(state, 'current', externalAthleteLink);
  },
};

const actions = {
  async create(
    context: ActionContext<ExternalAthleteLinkStoreState, unknown>,
    externalAthleteLink: ExternalAthleteLink,
  ): Promise<ExternalAthleteLink> {
    const newExternalAthleteLink = await externalAthleteLinkService.create(
      externalAthleteLink,
    );
    return newExternalAthleteLink;
  },
  async fetchAll(
    context: ActionContext<ExternalAthleteLinkStoreState, unknown>,
    payload?: Filter,
  ): Promise<ExternalAthleteLink[]> {
    const externalAthleteLinks = await externalAthleteLinkService.fetchAll(
      payload,
    );
    context.commit('setAll', externalAthleteLinks);
    return externalAthleteLinks;
  },
  async fetchForAthlete(
    context: ActionContext<ExternalAthleteLinkStoreState, unknown>,
    athleteId?: string,
  ): Promise<ExternalAthleteLink[]> {
    const externalAthleteLinks = await externalAthleteLinkService.fetchAll(
      new Filter([
        new FilterOperation(
          'athlete',
          athleteId,
          Operator.Equal,
          CastType.ObjectId,
        ),
      ]),
    );
    context.commit('setAll', externalAthleteLinks);
    return externalAthleteLinks;
  },
  async delete(
    context: ActionContext<ExternalAthleteLinkStoreState, unknown>,
    id: string,
  ): Promise<ExternalAthleteLink> {
    const externalAthleteLink = await externalAthleteLinkService.delete(id);
    return externalAthleteLink;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
