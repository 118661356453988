import { BaseModel } from '@/shared/types/base.model';

export class Ui extends BaseModel {
  constructor(
    public company?: string,
    public athlete?: string,
    public configuration: unknown = {},
  ) {
    super();
  }
}
