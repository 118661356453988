import HealthPointService from '@/api/health-point/health-point.service';
import { HealthPoint } from '@/api/health-point/model/health-point.model';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

interface HealthPointsPerType {
  [key: string]: HealthPoint[];
}

interface LoadersPerType {
  [key: string]: boolean;
}

interface HealthPointStoreState {
  dataPerType: HealthPointsPerType;
  loadersPerType: LoadersPerType;
  all: HealthPoint[];
  aggregated: any[];
}

const state: HealthPointStoreState = {
  dataPerType: {},
  loadersPerType: {},
  all: [],
  aggregated: [],
};

const getters = {
  dataForType:
    (state: HealthPointStoreState) =>
    (type: string): HealthPoint[] => {
      return state.dataPerType[type];
    },
  isTypeLoading:
    (state: HealthPointStoreState) =>
    (type: string): boolean => {
      return state.loadersPerType[type];
    },
  all: (state: HealthPointStoreState) => {
    return state.all;
  },
  aggregated: (state: HealthPointStoreState) => {
    return state.aggregated;
  },
};

const mutations = {
  setForType(
    state: HealthPointStoreState,
    payload: { type: string; healthPoints: HealthPoint[] },
  ) {
    Vue.set(state.dataPerType, payload.type, payload.healthPoints);
  },
  setLoaderForType(
    state: HealthPointStoreState,
    payload: { type: string; loading: boolean },
  ) {
    state.loadersPerType[payload.type] = payload.loading;
  },
  setAll(state: HealthPointStoreState, healthPoints: HealthPoint[]) {
    state.all = healthPoints;
  },
  setAggregated(state: HealthPointStoreState, aggregated: any[]) {
    state.aggregated = aggregated;
  },
};

const actions = {
  async create(
    context: ActionContext<HealthPointStoreState, unknown>,
    healthPoint: HealthPoint,
  ): Promise<HealthPoint> {
    const newHealthPoint = await HealthPointService.create(healthPoint);
    return newHealthPoint;
  },
  async fetchForType(
    context: ActionContext<HealthPointStoreState, unknown>,
    payload: { type: string; filter?: Filter },
  ): Promise<HealthPoint[]> {
    context.commit('setLoaderForType', { type: payload.type, loading: true });
    const healthPoints = await HealthPointService.fetchAll(payload.filter);
    context.commit('setForType', { type: payload.type, healthPoints });
    context.commit('setLoaderForType', { type: payload.type, loading: false });
    return healthPoints;
  },
  async fetchAll(
    context: ActionContext<HealthPointStoreState, unknown>,
    filter?: Filter,
  ): Promise<HealthPoint[]> {
    const healthPoints = await HealthPointService.fetchAll(filter);
    context.commit('setAll', healthPoints);
    return healthPoints;
  },
  async fetchAggregated(
    context: ActionContext<HealthPointStoreState, unknown>,
    filter?: Filter,
  ): Promise<any[]> {
    const aggregated = await HealthPointService.fetchAggregated(filter);
    context.commit('setAggregated', aggregated);
    return aggregated;
  },
  async delete(
    context: ActionContext<HealthPointStoreState, unknown>,
    id: string,
  ): Promise<HealthPoint> {
    return HealthPointService.delete(id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
