import { Ability, AbilityClass } from '@casl/ability';
import { UserRole } from './api/auth/user-role.enum';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'is';
type Subjects =
  | 'ReloadData'
  | 'Company'
  | 'CompanySetting'
  | 'User'
  | 'HealthPoint'
  | 'HealthGoal'
  | 'Athlete'
  | 'MyDocument'
  | 'ActiveCampaign'
  | 'all'
  | UserRole;

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
