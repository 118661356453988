import { Recommendation } from '@/api/recommendation/recommendation.model';
import { RecommendationService } from '@/api/recommendation/recommendation.service';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const recommendationService = new RecommendationService();

interface RecommendationStoreState {
  all: Recommendation[];
  current: Recommendation;
}

const state: RecommendationStoreState = {
  all: [],
  current: new Recommendation(),
};

const getters = {
  all: (state: RecommendationStoreState): Recommendation[] => {
    return state.all;
  },
  current: (state: RecommendationStoreState): Recommendation => {
    return state.current;
  },
};

const mutations = {
  setAll(state: RecommendationStoreState, recommendations: Recommendation[]) {
    Vue.set(state, 'all', recommendations);
  },
  setCurrent(state: RecommendationStoreState, recommendation: Recommendation) {
    Vue.set(state, 'current', recommendation);
  },
};

const actions = {
  async create(
    context: ActionContext<RecommendationStoreState, unknown>,
    recommendation: Recommendation,
  ): Promise<Recommendation> {
    const newRecommendation = await recommendationService.create(
      recommendation,
    );
    return newRecommendation;
  },
  async fetchById(
    context: ActionContext<RecommendationStoreState, unknown>,
    id: string,
  ): Promise<Recommendation> {
    const recommendation = await recommendationService.fetchOne(id);
    context.commit('setCurrent', recommendation);
    return recommendation;
  },
  async updateById(
    context: ActionContext<RecommendationStoreState, unknown>,
    recommendation: Recommendation,
  ): Promise<Recommendation> {
    const resultRecommendation = await recommendationService.update(
      recommendation._id,
      recommendation,
    );
    context.commit('setCurrent', resultRecommendation);
    return resultRecommendation;
  },
  async fetchAll(
    context: ActionContext<RecommendationStoreState, unknown>,
    payload?: Filter,
  ): Promise<Recommendation[]> {
    const recommendations = await recommendationService.fetchAll(payload);
    context.commit('setAll', recommendations);
    return recommendations;
  },
  async deleteById(
    context: ActionContext<RecommendationStoreState, unknown>,
    id: string,
  ): Promise<Recommendation> {
    const recommendation = await recommendationService.delete(id);
    return recommendation;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
