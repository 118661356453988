import { ActiveCampaign } from './active-campaign.model';
import BaseService from '../base-service';
import axios from '@/plugins/axios.plugin';

export class ActiveCampaignService extends BaseService<ActiveCampaign> {
  get endpoint() {
    return 'active-campaign';
  }

  sync(id: string): Promise<void> {
    return axios.post(`${this.endpoint}/${id}/sync`);
  }

  ensureFields(id: string): Promise<void> {
    return axios.post(`${this.endpoint}/${id}/ensure-fields`);
  }
}
