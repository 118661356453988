import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlusMinus,
  faArrowDown,
  faArrowsAltH,
  faArrowsRotate,
  faArrowUp,
  faArrowLeft,
  faBuilding,
  faBullseye,
  faChartBar,
  faChartLine,
  faChartSimple,
  faClipboard,
  faClock,
  faCogs,
  faEquals,
  faEuroSign,
  faExchangeAlt,
  faNetworkWired,
  faShuffle,
  faSignOutAlt,
  faStopwatch,
  faUpload,
  faUser,
  faUserFriends,
  faLink,
  faUserGraduate,
  faQuestionCircle,
  faSave,
  faPlus,
  faGear,
  faPenToSquare,
  faUserNinja,
  faMedal,
  faFolder,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
  faFileAlt,
  faPenToSquare,
  faFolder,
  faGear,
  faPlus,
  faQuestionCircle,
  faSave,
  faLink,
  faPlusMinus,
  faEquals,
  faArrowDown,
  faArrowsAltH,
  faArrowsRotate,
  faArrowUp,
  faBuilding,
  faBullseye,
  faChartBar,
  faChartLine,
  faChartSimple,
  faClipboard,
  faClock,
  faCogs,
  faEuroSign,
  faExchangeAlt,
  faNetworkWired,
  faShuffle,
  faSignOutAlt,
  faStopwatch,
  faUpload,
  faUser,
  faUserFriends,
  faUserGraduate,
  faArrowLeft,
  faUserNinja,
  faMedal,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
