import axios from '@/plugins/axios.plugin';
import BaseService from '../base-service';
import { Invite } from './invite.model';
import { User } from './user.model';

class UserService extends BaseService<User> {
  get endpoint() {
    return 'user';
  }

  async getLoggedInUser(): Promise<User> {
    const response = await axios.get<User>(`${this.endpoint}/me`);
    return response.data;
  }

  async inviteUser(invite: Invite): Promise<User> {
    const response = await axios.post<User>(`${this.endpoint}/invite`, invite);
    return response.data;
  }

  async resendInvite(email: string): Promise<void> {
    const response = await axios.post<void>(
      `${this.endpoint}/resend-invite/${email}`,
    );
    return response.data;
  }
}

export default new UserService();
