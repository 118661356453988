import { BaseModel } from '@/shared/types/base.model';
import { File } from '@/shared/types/file.model';

export class MyDocument extends BaseModel {
  constructor(
    public name?: string,
    public type: string = 'Medicavisie',
    public documentDate: Date = new Date(),
    public athlete?: string,
    public description?: string,
    public file?: string | File,
  ) {
    super();
  }
}
