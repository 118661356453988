import { BaseModel } from '@/shared/types/base.model';
import { HealthPointType } from '../health-point/type/health-point-type.enum';

export class Recommendation extends BaseModel {
  constructor(
    public name?: string,
    public description?: string,
    public dataType?: HealthPointType,
    public value?: number,
    public color: string = 'red',
    public active: boolean = true,
    public showToMembers: boolean = false,
    public company?: string,
  ) {
    super();
  }
}
