import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app.store';
import athleteNote from './modules/athlete-note.store';
import athlete from './modules/athlete.store';
import auth from './modules/auth.store';
import companySetting from './modules/company-setting.store';
import company from './modules/company.store';
import externalAthleteLink from './modules/external-athlete-link.store';
import healthGoal from './modules/health-goal.store';
import healthPoint from './modules/health-point.store';
import ui from './modules/ui.store';
import user from './modules/user.store';
import suggestion from './modules/suggestion.store';
import recommendation from './modules/recommendation.store';
import leaderboard from './modules/leaderboard.store';
import myDocument from './modules/my-document.store';
import activeCampaign from './modules/active-campaign.store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    athlete,
    auth,
    company,
    healthGoal,
    healthPoint,
    user,
    externalAthleteLink,
    athleteNote,
    ui,
    companySetting,
    suggestion,
    recommendation,
    leaderboard,
    myDocument,
    activeCampaign,
  },
});
