import { Athlete } from '@/api/athlete/athlete.model';
import { AthleteService } from '@/api/athlete/athlete.service';
import { DateRange } from '@/components/DateRangeSelect.vue';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const athleteService = new AthleteService();

interface AthleteStoreState {
  current: Athlete;
  all: Athlete[];
  selectedAthlete: Athlete;
}

const state: AthleteStoreState = {
  current: new Athlete(),
  all: [],
  selectedAthlete: JSON.parse(localStorage.getItem('athlete')),
};

const getters = {
  current: (state: AthleteStoreState): Athlete => {
    return state.current;
  },
  all: (state: AthleteStoreState): Athlete[] => {
    return state.all;
  },
  selectedAthlete: (state: AthleteStoreState): Athlete => {
    return state.selectedAthlete;
  },
};

const mutations = {
  setCurrent(state: AthleteStoreState, athlete: Athlete) {
    Vue.set(state, 'current', athlete);
  },
  setAll(state: AthleteStoreState, athletes: Athlete[]) {
    Vue.set(state, 'all', athletes);
  },
  selectAthlete(state: AthleteStoreState, athlete: Athlete) {
    DateRange.clearPersistedDateRange();
    if (!athlete) {
      Vue.set(state, 'selectedAthlete', undefined);
      localStorage.removeItem('athlete');
      return;
    }
    Vue.set(state, 'selectedAthlete', athlete);
    localStorage.setItem('athlete', JSON.stringify(athlete));
  },
  clearSelectedAthlete(state: AthleteStoreState) {
    DateRange.clearPersistedDateRange();
    Vue.set(state, 'selectedAthlete', undefined);
    localStorage.removeItem('athlete');
  },
};

const actions = {
  async create(
    context: ActionContext<AthleteStoreState, unknown>,
    athlete: Athlete,
  ): Promise<Athlete> {
    const newAthlete = await athleteService.create(athlete);
    context.commit('setCurrent', newAthlete);
    return newAthlete;
  },
  async fetchAll(
    context: ActionContext<AthleteStoreState, unknown>,
    payload?: Filter,
  ): Promise<Athlete[]> {
    const athletes = await athleteService.fetchAll(payload);
    context.commit('setAll', athletes);
    return athletes;
  },
  async fetchById(
    context: ActionContext<AthleteStoreState, unknown>,
    id: string,
  ): Promise<Athlete> {
    const athlete = await athleteService.fetchOne(id);
    context.commit('setCurrent', athlete);
    return athlete;
  },
  async updateById(
    context: ActionContext<AthleteStoreState, unknown>,
    athlete: Athlete,
  ): Promise<Athlete> {
    const updatedAthlete = await athleteService.update(athlete._id, athlete);
    context.commit('setCurrent', updatedAthlete);
    return updatedAthlete;
  },
  async addSampleAthlete(): Promise<void> {
    await athleteService.generateSampleAthlete();
  },
  async delete(
    context: ActionContext<AthleteStoreState, unknown>,
    id: string,
  ): Promise<Athlete> {
    return athleteService.delete(id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
