import { ActiveCampaign } from '@/api/active-campaign/active-campaign.model';
import { ActiveCampaignService } from '@/api/active-campaign/active-campaign.service';
import { DateRange } from '@/components/DateRangeSelect.vue';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const activeCampaignService = new ActiveCampaignService();

interface ActiveCampaignStoreState {
  current: ActiveCampaign;
  all: ActiveCampaign[];
}

const state: ActiveCampaignStoreState = {
  current: new ActiveCampaign(),
  all: [],
};

const getters = {
  current: (state: ActiveCampaignStoreState): ActiveCampaign => {
    return state.current;
  },
  all: (state: ActiveCampaignStoreState): ActiveCampaign[] => {
    return state.all;
  },
};

const mutations = {
  setCurrent(state: ActiveCampaignStoreState, activeCampaign: ActiveCampaign) {
    Vue.set(state, 'current', activeCampaign);
  },
  setAll(state: ActiveCampaignStoreState, activeCampaigns: ActiveCampaign[]) {
    Vue.set(state, 'all', activeCampaigns);
  },
};

const actions = {
  async create(
    context: ActionContext<ActiveCampaignStoreState, unknown>,
    activeCampaign: ActiveCampaign,
  ): Promise<ActiveCampaign> {
    const newActiveCampaign = await activeCampaignService.create(
      activeCampaign,
    );
    context.commit('setCurrent', newActiveCampaign);
    return newActiveCampaign;
  },
  async fetchAll(
    context: ActionContext<ActiveCampaignStoreState, unknown>,
    payload?: Filter,
  ): Promise<ActiveCampaign[]> {
    const activeCampaigns = await activeCampaignService.fetchAll(payload);
    context.commit('setAll', activeCampaigns);
    return activeCampaigns;
  },
  async fetchById(
    context: ActionContext<ActiveCampaignStoreState, unknown>,
    id: string,
  ): Promise<ActiveCampaign> {
    const activeCampaign = await activeCampaignService.fetchOne(id);
    context.commit('setCurrent', activeCampaign);
    return activeCampaign;
  },
  async updateById(
    context: ActionContext<ActiveCampaignStoreState, unknown>,
    activeCampaign: ActiveCampaign,
  ): Promise<ActiveCampaign> {
    const updatedActiveCampaign = await activeCampaignService.update(
      activeCampaign._id,
      activeCampaign,
    );
    context.commit('setCurrent', updatedActiveCampaign);
    return updatedActiveCampaign;
  },
  async delete(
    context: ActionContext<ActiveCampaignStoreState, unknown>,
    id: string,
  ): Promise<ActiveCampaign> {
    return activeCampaignService.delete(id);
  },
  async syncById(
    context: ActionContext<ActiveCampaignStoreState, unknown>,
    id: string,
  ): Promise<void> {
    return activeCampaignService.sync(id);
  },
  async ensureFieldsById(
    context: ActionContext<ActiveCampaignStoreState, unknown>,
    id: string,
  ): Promise<void> {
    return activeCampaignService.ensureFields(id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
