import { Ui } from './ui.model';
import BaseService from '../base-service';
import axios from '@/plugins/axios.plugin';

export class UiService extends BaseService<Ui> {
  get endpoint() {
    return 'ui';
  }

  async fetchForAthleteOrDefault(athlete: string): Promise<Ui> {
    const response = await axios.get<Ui>(
      `${this.endpoint}/athlete/${athlete}/include-default`,
    );

    return response.data;
  }

  async fetchForAthlete(athlete: string): Promise<Ui> {
    const response = await axios.get<Ui>(`${this.endpoint}/athlete/${athlete}`);

    return response.data;
  }

  async fetchForCompany(company: string): Promise<Ui> {
    const response = await axios.get<Ui>(`${this.endpoint}/company/${company}`);

    return response.data;
  }
}
