import { Ui } from '@/api/ui/ui.model';
import { Filter } from '@/shared/types/filter.class';

import { UiService } from '@/api/ui/ui.service';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const uiService = new UiService();

interface UiStoreState {
  all: Ui[];
  current: Ui;
  active: Ui;
}

const state: UiStoreState = {
  all: [],
  current: new Ui(),
  active: new Ui(),
};

const getters = {
  all: (state: UiStoreState): Ui[] => {
    return state.all;
  },
  current: (state: UiStoreState): Ui => {
    return state.current;
  },
  active: (state: UiStoreState): Ui => {
    return state.active;
  },
};

const mutations = {
  setAll(state: UiStoreState, uis: Ui[]) {
    Vue.set(state, 'all', uis);
  },
  setCurrent(state: UiStoreState, ui: Ui) {
    Vue.set(state, 'current', ui);
  },
  setActive(state: UiStoreState, ui: Ui) {
    Vue.set(state, 'active', ui);
  },
};

const actions = {
  async create(
    context: ActionContext<UiStoreState, unknown>,
    ui: Ui,
  ): Promise<Ui> {
    const newUi = await uiService.create(ui);
    return newUi;
  },
  async updateById(
    context: ActionContext<UiStoreState, unknown>,
    ui: Ui,
  ): Promise<Ui> {
    const updatedUi = await uiService.update(ui._id, ui);
    context.commit('setCurrent', updatedUi);
    return updatedUi;
  },
  async deleteById(
    context: ActionContext<UiStoreState, unknown>,
    id: string,
  ): Promise<Ui> {
    const updatedUi = await uiService.delete(id);
    return updatedUi;
  },
  async fetchForAthlete(
    context: ActionContext<UiStoreState, unknown>,
    athlete: string,
  ) {
    const ui = await uiService.fetchForAthlete(athlete);
    if (ui) {
      context.commit('setCurrent', ui);
    } else {
      context.commit('setCurrent', new Ui());
    }
  },
  async fetchForActiveAthlete(
    context: ActionContext<UiStoreState, unknown>,
    athlete: string,
  ) {
    const ui = await uiService.fetchForAthleteOrDefault(athlete);
    if (ui) {
      context.commit('setActive', ui);
    } else {
      context.commit('setActive', new Ui());
    }
  },
  async fetchForCompany(
    context: ActionContext<UiStoreState, unknown>,
    company: string,
  ) {
    const ui = await uiService.fetchForCompany(company);
    if (ui) {
      context.commit('setCurrent', ui);
    } else {
      context.commit('setCurrent', new Ui());
    }
  },
  async fetchAll(
    context: ActionContext<UiStoreState, unknown>,
    payload?: Filter,
  ): Promise<Ui[]> {
    const uis = await uiService.fetchAll(payload);
    context.commit('setAll', uis);
    return uis;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
