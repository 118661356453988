import { BaseModel } from '@/shared/types/base.model';

export class CompanySettingDetail {
  [key: string]: string;
}

export class CompanySetting extends BaseModel {
  public company: string;
  public settings: CompanySettingDetail;

  constructor() {
    super();
  }
}
