import { Athlete } from '@/api/athlete/athlete.model';
import { User } from '@/api/user/user.model';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Cockpit',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "cockpit" */ '../views/Cockpit.vue'),
  },
  {
    path: '/metric-detail/:metric',
    name: 'MetricDetail',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "metricdetail" */ '../views/MetricDetail.vue'
      ),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/magic-link/:token',
    name: 'MagicLink',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "MagicLink" */ '../views/MagicLink.vue'),
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "reset" */ '../views/PasswordReset.vue'),
  },
  {
    path: '/password-reset/:token(.*)',
    name: 'ExecutePasswordReset',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "resetexecute" */ '../views/ExecutePasswordReset.vue'
      ),
  },
  {
    path: '/register',
    name: 'Register',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  {
    path: '/register/confirm',
    name: 'RegisterVerifyMessage',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "registerverify" */ '../views/RegisterVerifyMessage.vue'
      ),
  },
  {
    path: '/activate/:token',
    name: 'ActivateAccount',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "activate" */ '../views/ActivateAccount.vue'),
  },
  {
    path: '/sign-up/:company',
    name: 'SignupForCompany',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "SignupForCompany" */ '../views/SignupForCompany.vue'
      ),
  },
  {
    path: '/connect/:token',
    name: 'ConnectionConfirmation',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "ConnectionConfirmation" */ '../views/ConnectionConfirmation.vue'
      ),
  },
  {
    path: '/connect/:token/succeeded',
    name: 'ConnectionSucceeded',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "ConnectionSucceeded" */ '../views/ConnectionSucceeded.vue'
      ),
  },
  {
    path: '/connect/:token/failed',
    name: 'ConnectionFailed',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "ConnectionFailed" */ '../views/ConnectionFailed.vue'
      ),
  },
  {
    path: '/athlete-select',
    name: 'AthleteSelect',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "athleteselect" */ '../views/AthleteSelect.vue'
      ),
  },
  {
    path: '/athletes',
    name: 'Athletes',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "athletes" */ '../views/Athletes.vue'),
  },
  {
    path: '/athletes/:id',
    name: 'AthleteDetail',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "athletedetail" */ '../views/AthleteDetail.vue'
      ),
  },
  {
    path: '/coaches',
    name: 'Coaches',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "coaches" */ '../views/Users.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue'),
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
  },
  {
    path: '/companies/:id',
    name: 'CompanyDetail',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "companyDetail" */ '../views/CompanyDetail.vue'
      ),
  },
  {
    path: '/import',
    name: 'Import',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "import" */ '../views/Import.vue'),
  },
  {
    path: '/health-point/create',
    name: 'HealthPointCreate',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "HealthPointDetail" */ '../views/HealthPointDetail.vue'
      ),
  },
  {
    path: '/health-goal/create',
    name: 'HealthGoalCreate',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "HealthGoalDetail" */ '../views/HealthGoalDetail.vue'
      ),
  },
  {
    path: '/login-as',
    name: 'LoginAs',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "LoginAs" */ '../views/LoginAs.vue'),
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "Leaderboard" */ '../views/Leaderboard.vue'),
  },
  {
    path: '/my-documents',
    name: 'MyDocuments',
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "MyDocuments" */ '../views/MyDocuments.vue'),
  },
  {
    path: '/my-documents/create',
    name: 'MyDocumentCreate',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MyDocumentCreate" */ '../views/MyDocumentCreate.vue'
      ),
  },
  {
    path: '/request-connection',
    name: 'RequestDataConnection',
    meta: {
      noSidebar: true,
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "RequestDataConnection" */ '../views/RequestDataConnection.vue'
      ),
  },
  {
    path: '/request-connection-once',
    name: 'OnlyRequestDataConnection',
    meta: {
      noSidebar: true,
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "RequestDataConnection" */ '../views/RequestDataConnection.vue'
      ),
  },
  {
    path: '/weekly-report',
    name: 'WeeklyReport',
    meta: {
      noSidebar: true,
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "RequestDataConnection" */ '../views/WeeklyReport.vue'
      ),
  },
  {
    path: '/active-campaign',
    name: 'ActiveCampaign',
    meta: {
      protected: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ActiveCampaigns" */ '../views/ActiveCampaigns.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  if (!to.meta.protected) {
    return next();
  }

  if (!localStorage.getItem('token')) {
    return next({ name: 'Login', query: { redirect: to.fullPath } });
  }

  try {
    await store.dispatch('user/fetchLoggedInUser');
  } catch (e) {
    console.log('not logged in');
  }
  const loggedInUser: User = store.getters['user/loggedInUser'];

  if (!loggedInUser) {
    return next({ name: 'Login', query: { redirect: to.fullPath } });
  }

  if (to.name === 'RequestDataConnection') {
    return next();
  }

  const selectedAthlete: Athlete = store.getters['athlete/selectedAthlete'];
  if (!selectedAthlete) {
    return next({ name: 'AthleteSelect', query: { redirect: to.fullPath } });
  }
  try {
    await store.dispatch('ui/fetchForActiveAthlete', selectedAthlete._id);
  } catch (e) {
    console.log('could not get ui');
  }

  if (to.meta.onlyRoles && !to.meta.onlyRoles.includes(loggedInUser.role)) {
    return next({ name: 'PageForbidden' });
  }
  return next();
});

export default router;
