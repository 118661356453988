import store from '@/store';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_API,
});

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = store.getters['auth/token'];
    const projectId = store.getters['auth/project'];

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.project = projectId;
    }

    return config;
  },
  (err: AxiosError) => {
    return Promise.reject(err);
  },
);

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response.data);
  },
);

export default http;
