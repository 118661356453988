import axios from '@/plugins/axios.plugin';
import BaseService from '../base-service';
import { CompanySetting } from './company-setting.model';

export class CompanySettingService extends BaseService<CompanySetting> {
  get endpoint() {
    return 'company-setting';
  }

  async fetchForCompany(company: string): Promise<CompanySetting> {
    const response = await axios.get<CompanySetting>(
      `${this.endpoint}/company/${company}`,
    );

    return response.data;
  }
}
