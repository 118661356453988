import { AthleteNote } from '@/api/athlete-note/athlete-note.model';
import { Filter } from '@/shared/types/filter.class';

import { AthleteNoteService } from '@/api/athlete-note/athlete-note.service';
import Vue from 'vue';
import { ActionContext } from 'vuex';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const athleteNoteService = new AthleteNoteService();

interface AthleteNoteStoreState {
  all: AthleteNote[];
  current: AthleteNote;
}

const state: AthleteNoteStoreState = {
  all: [],
  current: new AthleteNote(),
};

const getters = {
  all: (state: AthleteNoteStoreState): AthleteNote[] => {
    return state.all;
  },
  current: (state: AthleteNoteStoreState): AthleteNote => {
    return state.current;
  },
};

const mutations = {
  setAll(state: AthleteNoteStoreState, athleteNotes: AthleteNote[]) {
    Vue.set(state, 'all', athleteNotes);
  },
  setCurrent(state: AthleteNoteStoreState, athleteNote: AthleteNote) {
    Vue.set(state, 'current', athleteNote);
  },
};

const actions = {
  async create(
    context: ActionContext<AthleteNoteStoreState, unknown>,
    athleteNote: AthleteNote,
  ): Promise<AthleteNote> {
    const newAthleteNote = await athleteNoteService.create(athleteNote);
    return newAthleteNote;
  },
  async fetchAll(
    context: ActionContext<AthleteNoteStoreState, unknown>,
    payload?: Filter,
  ): Promise<AthleteNote[]> {
    const athleteNotes = await athleteNoteService.fetchAll(payload);
    context.commit('setAll', athleteNotes);
    return athleteNotes;
  },
  async delete(
    context: ActionContext<AthleteNoteStoreState, unknown>,
    id: string,
  ): Promise<AthleteNote> {
    try {
      return athleteNoteService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
