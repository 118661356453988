import { Invite } from '@/api/user/invite.model';
import { User } from '@/api/user/user.model';
import UsersService from '@/api/user/user.service';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import Toast from '@/shared/types/toast.class';
import Vue from 'vue';
import { ActionContext } from 'vuex';

interface UserStoreState {
  loggedInUser?: User;
  all: User[];
  pagination: PaginateResult<User>;
  current: User;
}

const state: UserStoreState = {
  loggedInUser: null,
  all: [],
  pagination: new PaginateResult<User>(),
  current: {},
};

const getters = {
  pagination: (state: UserStoreState) => {
    return state.pagination;
  },
  loggedInUser: (state: UserStoreState) => {
    return state.loggedInUser;
  },
  all: (state: UserStoreState) => {
    return state.all;
  },
  current: (state: UserStoreState) => {
    return state.current;
  },
};

const mutations = {
  setPagination(state: UserStoreState, pagination: PaginateResult<User>) {
    Vue.set(state, 'pagination', pagination);
  },
  setLoggedInUser(state: UserStoreState, loggedInUser: User) {
    Vue.set(state, 'loggedInUser', loggedInUser);
  },
  setAll(state: UserStoreState, users: User[]) {
    Vue.set(state, 'all', users);
  },
  setCurrent(state: UserStoreState, user: User) {
    Vue.set(state, 'current', user);
  },
};

const actions = {
  async fetchLoggedInUser(context: ActionContext<UserStoreState, unknown>) {
    let loggedInUser: User | undefined;
    try {
      loggedInUser = await UsersService.getLoggedInUser();
      context.commit('setLoggedInUser', loggedInUser);
    } catch (e) {
      context.commit('setLoggedInUser', undefined);
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
    }
    return loggedInUser;
  },

  async fetchById(
    context: ActionContext<UserStoreState, unknown>,
    id: string,
  ): Promise<User> {
    const user = await UsersService.fetchOne(id);
    context.commit('setCurrent', user);
    return user;
  },
  async fetchAll(
    context: ActionContext<UserStoreState, unknown>,
    payload?: Filter,
  ): Promise<User[]> {
    const users = await UsersService.fetchAll(payload);
    context.commit('setAll', users);
    return users;
  },
  async inviteUser(
    context: ActionContext<UserStoreState, unknown>,
    invite: Invite,
  ): Promise<User> {
    try {
      return await UsersService.inviteUser(invite);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async delete(
    context: ActionContext<UserStoreState, unknown>,
    id: string,
  ): Promise<User> {
    try {
      return UsersService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(
    context: ActionContext<UserStoreState, unknown>,
    user: User,
  ): Promise<User> {
    try {
      const userRet = await UsersService.update(user._id || '', user);
      context.commit('setCurrent', userRet);
      return userRet;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.Message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
