import { UserRole } from '@/api/auth/user-role.enum';
import { User } from '@/api/user/user.model';
import { AppAbility } from '@/AppAbility';
import { AbilityBuilder } from '@casl/ability';

export function defineRules(user?: User) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  if (!user) {
    return new AppAbility(rules);
  }
  can('is', user?.role);

  if (user?.role === UserRole.Admin) {
    can('manage', 'all');
  } else if (user?.role === UserRole.Manager) {
    can('manage', 'User');
    can('manage', 'HealthPoint');
    can('manage', 'HealthGoal');
    can('manage', 'Athlete');
    can('manage', 'MyDocument');
  } else if (user?.role === UserRole.Coach) {
    can('manage', 'HealthPoint');
    can('manage', 'HealthGoal');
    can('manage', 'Athlete');
    can('manage', 'MyDocument');
  } else if (user?.role === UserRole.Athlete) {
    can('read', 'HealthPoint');
    can('read', 'HealthGoal');
    can('read', 'MyDocument');
  }

  return new AppAbility(rules);
}
