import { HealthPoint } from './model/health-point.model';
import BaseService from '../base-service';
import { Filter } from '@/shared/types/filter.class';
import axios from '@/plugins/axios.plugin';

class HealthPointService extends BaseService<HealthPoint> {
  get endpoint() {
    return 'health-point';
  }

  async fetchAggregatedMax(params?: Filter): Promise<any[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/aggregated-max`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<HealthPoint[]>(endpoint, {
      params: params?.toParams(),
    });

    return response.data;
  }

  async fetchAggregatedMin(params?: Filter): Promise<any[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/aggregated-min`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<HealthPoint[]>(endpoint, {
      params: params?.toParams(),
    });

    return response.data;
  }
  async fetchAggregatedUsers(params?: Filter): Promise<any[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/aggregated-users`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<HealthPoint[]>(endpoint, {
      params: params?.toParams(),
    });

    return response.data;
  }
  async fetchAggregatedCount(params?: Filter): Promise<any[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/aggregated-count`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<HealthPoint[]>(endpoint, {
      params: params?.toParams(),
    });

    return response.data;
  }
}

export default new HealthPointService();
