import { BaseModel } from '@/shared/types/base.model';

export class Company extends BaseModel {
  public name: string;
  public email: string;
  public isActive = true;
  constructor() {
    super();
  }
}
