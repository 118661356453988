import { BaseModel } from '@/shared/types/base.model';

export class ExternalAthleteLink extends BaseModel {
  public athlete: string;
  public provider: string;
  public externalId: string;

  constructor() {
    super();
  }
}
