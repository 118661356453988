import Toast from '@/shared/types/toast.class';
import Vue from 'vue';

interface AppStoreState {
  loaders: { [key: string]: boolean };
  vm?: Vue;
}

const state: AppStoreState = {
  loaders: {},
  vm: undefined,
};

const getters = {
  loaders: (state: AppStoreState) => {
    return state.loaders;
  },
  isLoading: (state: AppStoreState) => (id: string) => {
    return state.loaders[id];
  },
};

const mutations = {
  addLoader(state: AppStoreState, id: string) {
    Vue.set(state.loaders, id, true);
  },
  removeLoader(state: AppStoreState, id: string) {
    Vue.delete(state.loaders, id);
  },
  addToast(state: AppStoreState, toast: Toast) {
    if (!state.vm) {
      return;
    }
    state.vm.$bvToast.toast(toast.message, {
      title: state.vm.$t(toast.type) as string,
      toaster: 'b-toaster-bottom-right',
      appendToast: true,
      solid: true,
      variant: toast.type,
    });
  },
  setAppContext(state: AppStoreState, vm: Vue) {
    state.vm = vm;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
