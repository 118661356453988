import { CompanySetting } from '@/api/company-setting/company-setting.model';
import { CompanySettingService } from '@/api/company-setting/company-setting.service';
import Vue from 'vue';
import { ActionContext } from 'vuex';

const companySettingService = new CompanySettingService();

interface CompanySettingStoreState {
  current: CompanySetting;
}

const state: CompanySettingStoreState = {
  current: new CompanySetting(),
};

const getters = {
  current: (state: CompanySettingStoreState): CompanySetting => {
    return state.current;
  },
};

const mutations = {
  setCurrent(state: CompanySettingStoreState, companySetting: CompanySetting) {
    Vue.set(state, 'current', companySetting);
  },
};

const actions = {
  async update(
    context: ActionContext<CompanySettingStoreState, unknown>,
    companySetting: CompanySetting,
  ): Promise<CompanySetting> {
    const newCompanySetting = await companySettingService.partialUpdate(
      companySetting._id,
      companySetting,
    );
    return newCompanySetting;
  },
  async fetchForCompany(
    context: ActionContext<CompanySettingStoreState, unknown>,
    company: string,
  ): Promise<CompanySetting> {
    const companySetting = await companySettingService.fetchForCompany(company);
    context.commit('setCurrent', companySetting);
    return companySetting;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
